exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js?export=default" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx?export=default" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx?export=default" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx?export=default" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-denormandie-device-jsx": () => import("./../../../src/templates/denormandie-device.jsx?export=default" /* webpackChunkName: "component---src-templates-denormandie-device-jsx" */),
  "component---src-templates-furnished-rental-jsx": () => import("./../../../src/templates/furnished-rental.jsx?export=default" /* webpackChunkName: "component---src-templates-furnished-rental-jsx" */),
  "component---src-templates-legal-notices-jsx": () => import("./../../../src/templates/legal-notices.jsx?export=default" /* webpackChunkName: "component---src-templates-legal-notices-jsx" */)
}



exports.head = {

}

